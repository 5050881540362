import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

import { EnvironmentBase } from '../models/environment-base';
import { ENVIRONMENT } from '../tokens/environment.token';
import { IS_MOBILE_APP } from '../tokens/is-mobile-app.token';
import { loadScriptDynamically } from '../util/load-script-dynamically';

export const PAYPAL_SCRIPT_ID: string = 'paypal-script';

@Injectable({ providedIn: 'root' })
export class PaypalConfigService {
  constructor(
    @Inject(ENVIRONMENT) private readonly _environment: EnvironmentBase,
    @Inject(DOCUMENT) private readonly _document: Document,
    @Inject(IS_MOBILE_APP) private readonly _isMobileApp: boolean,
  ) {}

  loadPaypalScript(): void {
    if (this._isMobileApp) {
      return;
    }

    if (!this._document.getElementById(PAYPAL_SCRIPT_ID)) {
      const params = [
        `client-id=${this._environment.PAYPAL_CLIENT_ID}`,
        'vault=true',
        'currency=EUR',
        'components=buttons',
        'intent=subscription',
      ];

      loadScriptDynamically(`https://www.paypal.com/sdk/js?${params.join('&')}`, PAYPAL_SCRIPT_ID, this._document);
    }
  }
}
