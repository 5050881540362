export function loadScriptDynamically(scriptLink: string, scriptId: string, document: Document, scriptType?: string): void {
  const script = document.createElement('script');
  script.id = scriptId;
  script.src = scriptLink;
  script.async = true;

  if (scriptType) {
    script.type = scriptType;
  }
  document.head.appendChild(script);
}
