import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { map, Observable, tap, throwError } from 'rxjs';

import { ANALYTICS_SERVICE, AnalyticsBaseService } from '@stsm/analytics';
import { LeadAnswer, LeadSubmitData } from '@stsm/global/models/lead-question';
import { LeadType } from '@stsm/global/models/lead-type';
import { BaseService } from '@stsm/shared/services/base.service';
import { JsonObject } from '@stsm/shared/types/json-object';
import { switchToVoid } from '@stsm/shared/util/rxjs.util';

@Injectable({ providedIn: 'root' })
export class LeadsService {
  constructor(
    private readonly _baseService: BaseService,
    @Inject(ANALYTICS_SERVICE) private readonly _analyticsService: AnalyticsBaseService,
  ) {}

  sendLeadData(
    data: LeadSubmitData,
    contribution: { clientName: string; leadType: LeadType; adId?: number; clientId?: number },
  ): Observable<void> {
    if (isNil(contribution.adId) && isNil(contribution.clientId)) {
      return throwError(() => new Error('No lead contribution!'));
    }

    const { email, firstName, lastName, phoneNumber, answersToQuestions } = data;
    const body = {
      ...(!isNil(contribution.adId) ? { joboffer_id: contribution.adId } : {}),
      ...(!isNil(contribution.clientId) ? { client_id: contribution.clientId } : {}),
      email,
      name: `${firstName} ${lastName}`,
      phone_number: phoneNumber,
      answers: answersToQuestions.map((answer: LeadAnswer) => ({
        question_id: answer.questionId,
        answers: answer.answers,
      })),
    };

    return this._baseService.post(`leads/`, JSON.stringify(body)).pipe(
      map((res: JsonObject) => res['realized_revenue'] as number | undefined),
      tap((revenue: number | undefined) => {
        if (isNil(revenue)) {
          return;
        }

        this._analyticsService.trackEvent({
          action: 'lead_creation_complete',
          properties: { leadType: contribution.leadType, clientName: contribution.clientName },
          revenueProperties: { revenue, revenueType: 'lead' },
        });
      }),
      switchToVoid(),
    );
  }
}
